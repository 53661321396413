
import { defineComponent, PropType, watch, nextTick, ref, Ref, reactive } from "vue";
import echarts from "@/utils/echarts";
import getChartOption from "../composables/chartOption";
/** 饼图数据结构 */
interface FilterProps {
  xName: string;
  yName: string;
  xData: Array<string>;
  list: Array<{ label: string; data: [] }>;
}
export default defineComponent({
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    type: {
      type: Number as PropType<number>,
      default: 0
    },
    height: {
      type: Number,
      default: 350
    },
    emptyHeight: {
      type: Number
    },
    legend: {
      type: Object as PropType<Record<string, any>>,
      default: () => {
        return {};
      }
    },
    dataZoom: {
      type: Array as PropType<Array<Record<string, any>>>,
      default: () => {
        return [];
      }
    },
    data: {
      type: Object as PropType<FilterProps>,
      default: () => {
        return {
          xName: "",
          yName: "",
          xData: [],
          list: []
        };
      }
    }
  },
  setup(props) {
    let seriesNameList = ref([]) as Ref<Array<any>>;
    // 设置图例是否勾选
    let selectOption: any = reactive({});
    const initChart = () => {
      const chartRef = document.getElementById(props.id);
      let myEcharts = echarts.getInstanceByDom(chartRef as HTMLElement);
      if (!myEcharts) {
        myEcharts = echarts.init(chartRef as HTMLElement);
      }
      const option: any = getChartOption("line");
      const colorArr: Array<string> = ["#808080", "#1890ff"];
      const colorArr1: Array<string> = ["#1f9f85", "#009fff"];
      // type 0工时燃尽图 1成员工时燃尽图 2bug燃尽图
      option.xAxis[0].name = props.data.xName;
      option.yAxis[0].name = props.data.yName;
      option.xAxis[0].data = props.data.xData;
      props.data.list.forEach((i: any, index: number) => {
        const seriesObj: Record<string, any> = {
          name: "",
          showSymbol: true,
          symbol: "emptyCircle",
          symbolSize: 4,
          type: "line",
          smooth: true,
          lineStyle: {
            color: "",
            width: 1
          },
          data: []
        };
        seriesObj.name = i["label"];
        seriesObj.data = i["data"];
        seriesObj.color = props.type === 0 ? colorArr[index] : colorArr1[index];
        seriesObj.lineStyle.color = props.type === 0 ? colorArr[index] : colorArr1[index];
        option.series.push(seriesObj);
        // 设置前五个图例默认勾选
        selectOption[i.label] = false;
        if (index < 5) {
          selectOption[i.label] = true;
        }
      });
      if (props.legend) {
        option.legend = Object.assign({}, props.legend);
      } else if (props.type === 1) {
        // 成员工时燃尽图，legend设置成分页可滚动
        option.legend.type = "scroll";
        option.legend.pageIconSize = 12;
        option.legend.padding = [10, 0, 0, 90];
      }
      if (props.type === 0 && option.series.length) {
        option.series[0].showSymbol = false;
      }
      if (props.type === 2) {
        option.yAxis[0].minInterval = 1;
      }

      if (props.dataZoom.length) {
        option.dataZoom = props.dataZoom;
      }
      option.legend.selected = selectOption;
      myEcharts.setOption(option, true);
    };
    watch(
      () => props.data,
      (newVal) => {
        if (newVal.xData && newVal.xData.length) {
          nextTick(() => {
            initChart();
          });
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      seriesNameList,
      selectOption
    };
  }
});
