import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cb4c8b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "statistics-pie-chart" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data && _ctx.data.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: _ctx.id,
          style: _normalizeStyle({ width: '100%', height: _ctx.height + 'px' })
        }, null, 12, _hoisted_2))
      : (_openBlock(), _createBlock(_component_el_empty, {
          key: 1,
          description: "暂无数据"
        }))
  ]))
}