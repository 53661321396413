
import { defineComponent, PropType, watch, nextTick, reactive } from "vue";
import echarts from "@/utils/echarts";
import getChartOption from "../composables/chartOption";
interface pieParams {
  name: string;
  value: string | number;
}
export default defineComponent({
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    title: {
      type: Object as PropType<Record<string, any>>,
      default: () => {
        return {};
      }
    },
    type: {
      type: Number as PropType<number>
    },
    data: {
      type: Array as PropType<Array<pieParams>>,
      default: () => []
    },
    color: {
      type: Array as PropType<Array<string>>,
      default: () => []
    },
    height: {
      type: Number,
      default: 350
    },
    legend: {
      type: Object as PropType<Record<string, any>>,
      default: () => {
        return {};
      }
    },
    series: {
      type: Object as PropType<Record<string, any>>,
      default: () => {
        return {};
      }
    },
    showEmptyBlock: {
      // 数据为空时，是否展示饼图快
      type: Boolean,
      deafult: true
    }
  },
  setup(props) {
    // 设置图例是否勾选
    let selectOption: any = reactive({});
    const initChart = () => {
      const chartRef = document.getElementById(props.id);
      let myEcharts = echarts.getInstanceByDom(chartRef as HTMLElement);
      if (!myEcharts) {
        myEcharts = echarts.init(chartRef as HTMLElement);
      }
      let option: any = getChartOption("pie");
      option.series[0] = Object.assign(option.series[0], { ...props.series });
      option.series[0].data = props.data;
      if (props.showEmptyBlock) {
        props.data.forEach((i) => {
          selectOption[i.name] = true;
          if (!Number(i.value)) {
            selectOption[i.name] = false;
          }
        });
      }
      option.legend = Object.assign({ selected: selectOption }, props.legend);

      if (props.color.length) {
        option.color = props.color;
      }
      if (props.title) {
        option.title = props.title;
      }
      myEcharts.setOption(option, true);
    };
    watch(
      () => props.data,
      (newVal) => {
        if (newVal && newVal.length) {
          nextTick(() => {
            initChart();
          });
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      selectOption
    };
  }
});
